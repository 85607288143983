"use client";
import Header from "@/components/shared/Header";
import start from "@/public/images/waiting.svg";
import Image from "next/image";
import MyLink from "@/components/shared/myLink/MyLink";
import { SelectLoggedUser } from "@/redux/Auth";
import { useAppSelector } from "@/redux/store";

export default function LandingPage() {
  const loggedUser = useAppSelector(SelectLoggedUser);

  return (
    <>
      <Header />
      <div className="flex flex-col gap-4 items-center justify-center p-4">
        <Image
          src={start}
          alt="waiting..."
          width={300}
        />
        <div className="flex flex-col gap-4 max-w-3xl">
          {loggedUser.isLoggedIn &&
          loggedUser.userToken &&
          loggedUser.userData ? (
            <>
              <h2 className="text-center text-[#38b2f0] text-[40px] font-medium">
                مرحبًا بك، {loggedUser.userData.name}! 👋
              </h2>
              <p className="text-center text-[#77849e] text-[25px] font-medium capitalize">
                شكراً لإنضمامك لنا في منصة إكس هاب - X-Hub نحن سعداء بوجودك معنا
                ونتطلع لمساعدتك في رحلتك التعليمية إلى التفوق.
              </p>
            </>
          ) : (
            <>
              <h2 className="text-center text-[#38b2f0] text-[40px] font-medium">
                انشئ حسابك مجاناً الآن ولفترة محدودة فقط..!
              </h2>
              <p className="text-center text-[#77849e] text-[25px] font-medium capitalize">
                #وإنتظرونا_قريباً_جداً
              </p>
              <div className="flex items-center justify-center gap-2">
                <MyLink
                  href="/login"
                  className="text-center font-[500] text-white py-2 px-2 border border-white rounded-[7px] bg-gradient-to-b from-[#252F3927] to-[#252F3966]"
                >
                  تسجيل دخول
                </MyLink>
                <MyLink
                  href="/signup"
                  className="text-center font-[500] text-white bg-primaryColor rounded-[7px] py-2 px-2"
                >
                  إنشاء حساب
                </MyLink>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
